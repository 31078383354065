

import request from '@/libs/request';


export function getUserInfo() {
  return request({
    url: '/v3/user',
    method: 'get'
  });
}

export function userCourseList(data) {
  return request({
    url: '/v3/user/course',
    method: 'get',
	params:data
  });
}

export function userCourseAndJobList(data) {
  return request({
    url: '/v3/user/courseAndJob',
    method: 'get',
	params:data
  });
}




export function userCourseTryList(type) {
  return request({
    url: '/v3/user/course/try/'+type,
    method: 'get',
  });
}


export function userCourseLiveList(data) {
  return request({
    url: '/v3/user/course/live/'+data.course_id+'/'+data.term_id,
    method: 'get',
	params:data
  });
}

export function userCoursePlaybackList(live_id) {
  return request({
    url: '/v3/user/course/playback/'+live_id,
    method: 'get',
  });
}

export function userCourseChapterList(course_id) {
  return request({
    url: '/v3/course/chapter/'+course_id,
    method: 'get',
  });
}



export function userCourseLiveAuth(channel_id) {
  return request({
    url: '/v3/user/course/auth/'+channel_id,
    method: 'get',
  });
}

export function userCourseJobList(data) {
  return request({
    url: '/v3/user/course/job/'+data.course_id+'/'+data.term_id,
    method: 'get',
  });
}

export function userCourseJobInfo(live_id) {
  return request({
    url: '/v3/user/course/job/'+live_id,
    method: 'get',
  });
}

export function userCourseUserJobList(data,job_id) {
  return request({
    url: '/v3/user/course/job/user_job/list1/'+job_id,
    method: 'get',
	params:data
  });
}


export function subJob(job_id,data) {
  return request({
    url: '/v3/user/course/job/'+job_id,
    method: 'post',
	params:data
  });
}

export function delUserJob(job_id) {
  return request({
    url: '/v3/user/course/job/user_job/'+job_id,
    method: 'delete',
  });
}

export function getUserJobCommentList(user_job_id,data) {
  return request({
    url: '/v3/user/course/job/user_job/comment/list/'+user_job_id,
    method: 'get',
	params:data
  });
}

export function commentUserJob(user_job_id,data) {
  return request({
    url: '/v3/user/course/job/user_job/comment/'+user_job_id,
    method: 'post',
	params:data
  });
}



export function likeUserJob(job_id,data) {
  return request({
    url: '/v3/user/course/job/user_job/like/'+job_id,
    method: 'post',
	params:data
  });
}

/**
 * 绑定手机号
 *
 */
export function bindingUserPhone(data) {
	return request({
		url: '/v3/user/binding',
		method: 'post',
		params:data
	});
}

/**
 * 更换手机号码
 * @returns {*}
 */
export function updatePhone(data) {
	return request({
		url: '/user/updatePhone',
		method: 'post',
		params:data
	});
}


export function editUserInfo(data) {
	return request({
		url: '/v3/user/edit',
		method: 'post',
		params:data
	});
}

export function phoneRegisterReset(data) {
	return request({
		url: '/register/reset',
		method: 'post',
		params:data
	});
}

