<template>
	
	<div>
		<el-dialog
		  :visible.sync="centerDialogVisible"
		  :show-close="false"
		  :close-on-click-modal="true"
		  :close-on-press-escape="true"
		  class="login_dialog"
		  center>
			<div class="login_text">
				<div  class="login_fanhui" >
					<div  class="login_fanhui_img no_fanhui" >
						<img src="../assets/icon-back.png" v-if="loginType == 'phone'" @click="loginType = 'account'"/>
					</div>
					<div  style="font-size: 23px;">账号登录</div>
				</div>
				<div >
					<img  src="../assets/xia.png" alt="">
				</div>
			</div>
			
			<el-form ref="loginForm" :model="loginData" :rules="rules" v-if="loginType == 'account'">
			  <el-form-item prop="account">
			    <el-input v-model="loginData.account" placeholder="请输入学号/手机号"></el-input>
			  </el-form-item>
			  <el-form-item prop="password">
			    <el-input v-model="loginData.password" show-password placeholder="请输入登录密码"></el-input>
			  </el-form-item>
			  
			</el-form>
			
			<el-form ref="loginForm" :model="loginData" :rules="rules" v-if="loginType == 'phone'">
			  <el-form-item prop="account">
			    <el-input v-model="loginData.account" placeholder="请输入手机号"></el-input>
			  </el-form-item>
			  <el-form-item prop="captcha">
				  <div class="acea-row row-between-wrapper">
				  	<el-input v-model="loginData.captcha"  placeholder="请输入验证码" :maxlength="6" style="width: 230px;"></el-input>
				  	<button class="getCode" @click="code" type="button" :disabled="disabled">{{ text }}</button>
				  </div>
			    
			  </el-form-item>
			  
			</el-form>
			
			<div class="form_bottom_box">
				<div class="acea-row row-center">
					<button  @click="submitForm('loginForm')" v-if="loginType == 'account'" class="login_btn" :class="loginData.account && loginData.password ? 'islogin_btn' : ''" :disabled="!loginData.account || !loginData.password">快速登录</button>
					<button  @click="phoneLogin('loginForm')" v-if="loginType == 'phone'" class="login_btn" :class="loginData.account && loginData.captcha ? 'islogin_btn' : ''" :disabled="!loginData.account || !loginData.captcha">登录</button>
				
				
				</div>
				<div class="xieyi acea-row row-middle">
					<!-- <el-radio v-model="radio" label="1">同意并遵守</el-radio> -->
					<el-checkbox v-model="checked"></el-checkbox>
					同意并遵守
					<span class="xieyi_text" @click="privacy(2)">《用户协议》</span>
					 及 
					<span class="xieyi_text" @click="privacy(1)">《隐私协议》</span>
				</div>
				<div class="cut_login_mode" v-if="loginType == 'account'">
					<div class="cut_login_mode_flex" @click="loginType = 'phone'">
						<div>
							<img src="../assets/icon-login-phone.png" alt=""/>
						</div>
						<div class="cut_login_mode_text">验证码登录</div>
					</div>
				</div>	
					<!-- <div class="cut_login_mode_flex">
					</div> -->
			</div>
			<Verify @success="success" :captchaType="'blockPuzzle'"
				:imgSize="{ width: '330px', height: '155px' }" ref="verify"></Verify>
		</el-dialog>
	</div>
</template>

<script>
import {login,loginMobile} from '@/api/public';
import {getUserInfo} from '@/api/user';
import {setCookies} from '@/libs/util';
import sendVerifyCode from "@/mixins/SendVerifyCode";
import Verify from '@/components/verifition/Verify';

import {verifyCode,registerVerify} from '@/api/api';


export default {
	mixins: [sendVerifyCode],
	components: {
		Verify
	},
	props: {
		list:[]
	},
	data() {
		return {
			centerDialogVisible:false,
			loginData:{
				account:'',
				password:'',
				captcha:''
			},
			keyCode:'',
			loginType:'account',
			checked:false,
			rules: {
				account: [
					{ required: true, message: '请输入登录账号', trigger: 'blur' },
				],
				password: [
					{ required: true, message: '请输入登录密码', trigger: 'blur' }
				],
				captcha: [
					{ required: true, message: '请输入验证码', trigger: 'blur' }
				],
				
			}
		};
	},
	
	methods:{
		submitForm(formName) {
			let that = this;
			
			if(this.checked == false){
				that.$notify.error({
				  title: '错误',
				  message: '请先阅读并同意协议'
				});
				return false;
			}
			
			that.$refs[formName].validate((valid) => {
			  if (valid) {
				login(that.loginData).then(async (res) => {
					// 记录用户登陆信息
					let expires = that.getExpiresTime(res.data.expires_time);
					setCookies('token', res.data.token, expires);
					setCookies('expires_time', res.data.expires_time, expires);
					getUserInfo().then(async (r) => {
						that.$store.commit('setUserInfo', r.data);
						that.centerDialogVisible = false;
					})
					.catch((r) => {
						that.$notify.error({
						  title: '错误',
						  message: r.msg
						});
					});
				})
				.catch((res) => {
					this.$notify.error({
					  title: '错误',
					  message: res.msg
					});
				});
			  } else {
				return false;
			  }
			});
		  },
		  phoneLogin(formName) {
		  	let that = this;
		  	
		  	if(this.checked == false){
		  		that.$notify.error({
		  		  title: '错误',
		  		  message: '请先阅读并同意协议'
		  		});
		  		return false;
		  	}
		  	if (!that.loginData.account){
		  		return that.$notify.error({
		  		  title: '错误',
		  		  message: '请填写手机号码'
		  		});
		  	}
		  	if (!(/^1(3|4|5|7|8|9|6)\d{9}$/i.test(that.loginData.account))){
		  		return that.$notify.error({
		  		  title: '错误',
		  		  message: '请输入正确的手机号码'
		  		});
		  	}
		  	that.$refs[formName].validate((valid) => {
		  	  if (valid) {
		  		loginMobile({
						phone: that.loginData.account,
						captcha: that.loginData.captcha,
					}).then(async (res) => {
		  			// 记录用户登陆信息
		  			let expires = that.getExpiresTime(res.data.expires_time);
		  			setCookies('token', res.data.token, expires);
		  			setCookies('expires_time', res.data.expires_time, expires);
		  			getUserInfo().then(async (r) => {
		  				that.$store.commit('setUserInfo', r.data);
		  				that.centerDialogVisible = false;
		  			})
		  			.catch((r) => {
		  				that.$notify.error({
		  				  title: '错误',
		  				  message: r.msg
		  				});
		  			});
		  		})
		  		.catch((res) => {
		  			this.$notify.error({
		  			  title: '错误',
		  			  message: res.msg
		  			});
		  		});
		  	  } else {
		  		return false;
		  	  }
		  	});
		    },
		  privacy(type) {
			  window.open('/privacy?type='+type, '_blank');
		  },
		  code()
		  {
		  	let that = this;
			if(this.checked == false){
				that.$notify.error({
				  title: '错误',
				  message: '请先阅读并同意协议'
				});
				return false;
			}
		  	if (!that.loginData.account){
		  		return that.$notify.error({
		  		  title: '错误',
		  		  message: '请填写手机号码'
		  		});
		  	}
		  	if (!(/^1(3|4|5|7|8|9|6)\d{9}$/i.test(that.loginData.account))){
		  		return that.$notify.error({
		  		  title: '错误',
		  		  message: '请输入正确的手机号码'
		  		});
		  	}
		  	this.$refs.verify.show();
		  },
		  
		  async getCode(data) {
		  	let that = this;
		  	if(this.checked == false){
		  		that.$notify.error({
		  		  title: '错误',
		  		  message: '请先阅读并同意协议'
		  		});
		  		return false;
		  	}
		  	if (!that.loginData.account){
		  		return that.$notify.error({
		  		  title: '错误',
		  		  message: '请填写手机号码'
		  		});
		  	}
		  	if (!(/^1(3|4|5|7|8|9|6)\d{9}$/i.test(that.loginData.account))){
		  		return that.$notify.error({
		  		  title: '错误',
		  		  message: '请输入正确的手机号码'
		  		});
		  	}
		  
		  	await registerVerify(
		  			that.loginData.account,
		  			'login',
		  			that.keyCode,
		  			'blockPuzzle',
		  			data.captchaVerification
		  		)
		  		.then(res => {
		  			this.sendCode()
		  			// that.$util.Tips({
		  			// 	title: res.msg
		  			// });
		  		})
		  		.catch(err => {
		  			return that.$notify.error({
		  			  title: '错误',
		  			  message: err.msg
		  			});
		  		});
		  },
		  
		  success(data){
		  	this.$refs.verify.closeBox()
		  	verifyCode().then(res => {
				this.keyCode = res.data.key;
				this.getCode(data);
			})
			.catch(res => {
				this.$util.Tips({
					title: res
				});
			});
		  		
		  },
		getExpiresTime(expiresTime) {
		  let nowTimeNum = Math.round(new Date() / 1000);
		  let expiresTimeNum = expiresTime - nowTimeNum;
		  return parseFloat(parseFloat(parseFloat(expiresTimeNum / 60) / 60) / 24);
		},  
	}
};
</script>

<style scoped lang="scss">
	
	
.login_text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 400px;
	margin: 55px auto;
	.login_fanhui {
		width: 100%;
		display: flex;
		cursor:pointer;
		.no_fanhui {
			width: 155px;
		}
	}
}
.login_dialog  {
    margin: 0 50px;
}

.login_dialog /deep/ .el-dialog {
    width: 481px;
    height: 620px;
    border-radius: 35px;
    position: relative;
    margin-top: 20vh !important;
    background: #ffffff;
}
.login_dialog /deep/ .el-form {
    margin: 0 50px;
}

.login_dialog /deep/ .el-input__inner {
    border: none;
    padding-left: 10px;
    border-bottom: 1px solid #EEEEEE;
}

.getCode{
	width: 90px;
	text-align: center;
	border: 0;
	background: none;
	cursor:pointer
}
.form_bottom_box{
    margin-left: 48px;
    margin-right: 53px;
	.cut_login_mode{
		display: flex;
		margin-top: 50px;
		justify-content: space-between;
		.cut_login_mode_flex{
			width: 338px;
			display: flex;
			flex-direction: column;
			align-items: center;
			cursor:pointer;
			.cut_login_mode_text {
			    margin-top: 5px;
			}
		}
	}
	
	.xieyi{
	    padding: 0 10px;
	    margin-top: 15px;
	    cursor: default;
		.el-checkbox{
			margin-right:10px;
		}
		.xieyi_text {
		    color: #8798CF;
			cursor:pointer
		}
	}
}




.login_btn {
    width: 338px;
    height: 58px;
    margin-top: 25px;
    border-radius: 29px;
	border:0;
    opacity: 0.5;
    color: #FFFFFF;
    font-size: 19px;
    text-align: center;
    line-height: 58px;
    background: linear-gradient(to right, #3F73FA, #1C55E8);
}
.islogin_btn {
    background: linear-gradient(to right, #3F73FA, #1C55E8);
    opacity: 1;
	cursor:pointer
}
</style>