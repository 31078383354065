
import request from '@/libs/request';

export function homeData() {
  return request({
    url: '/v3/index',
    method: 'get'
  });
}
export function courseData() {
  return request({
    url: '/v3/course',
    method: 'get'
  });
}

export function certData() {
  return request({
    url: '/v3/cert',
    method: 'get'
  });
}

export function jobData() {
  return request({
    url: '/v3/job',
    method: 'get'
  });
}
export function newsData() {
  return request({
    url: '/v3/news',
    method: 'get'
  });
}



export function getSystemData() {
  return request({
    url: '/v3/config',
    method: 'get'
  });
}



export function courseList(data) {
  return request({
    url: '/v3/data/course',
    method: 'get',
	params:data
  });
}

export function courseCategoryList(data) {
  return request({
    url: '/v3/data/course/category',
    method: 'get',
	params:data
  });
}

export function coursePageList(data) {
  return request({
    url: '/v3/data/course/page',
    method: 'get',
	params:data
  });
}


export function courseDetail(id) {
  return request({
    url: '/v3/data/course/detail/'+id,
    method: 'get',
  });
}


export function jobCategoryList(data) {
  return request({
    url: '/v3/data/job/category',
    method: 'get',
	params:data
  });
}

export function jobPageList(data) {
  return request({
    url: '/v3/data/job/page',
    method: 'get',
	params:data
  });
}


export function jobDetail(id) {
  return request({
    url: '/v3/data/job/detail/'+id,
    method: 'get',
  });
}


export function certCategoryList(data) {
  return request({
    url: '/v3/data/cert/category',
    method: 'get',
	params:data
  });
}

export function certPageList(data) {
  return request({
    url: '/v3/data/cert/page',
    method: 'get',
	params:data
  });
}


export function certDetail(id) {
  return request({
    url: '/v3/data/cert/detail/'+id,
    method: 'get',
  });
}


export function login(data) {
  return request({
    url: '/v3/login',
    method: 'post',
	params:data
  });
}


export function loginMobile(data) {
	return request({
	  url: '/v3/login/mobile',
	  method: 'post',
	  params:data
	});
}

/*
 * 获取隐私协议
 * */
export function getAgreement(type) {
	return request({
	  url: `v3/get_agreement/${type}`,
	  method: 'get'
	});
	
}



export function newsCategoryList(data) {
  return request({
    url: '/v3/data/article_category/list',
    method: 'get',
	params:data
  });
}

export function newsPageList(data) {
  return request({
    url: '/v3/data/article/page',
    method: 'get',
	params:data
  });
}


export function newsDetail(id) {
  return request({
    url: '/v3/data/article/detail/'+id,
    method: 'get',
  });
}