<template>
	<div class="content_box">
		<div class="container">
			
			<div class="breadcrumbs">
				<span>课程</span>
				<span class="sep">/</span>
				<span>{{info.name}}</span>
			</div>
			
			<div class="course-body">
				
				<div class="pic">
					<img :src="info.thumb"/>
				</div>
				<div class="course-info">
					<div class="course-info_wrap">
						<div class="wrap__top">
							<h3>{{info.name}}</h3>
							<div class="acea-row row-between-wrapper" v-if="info.virtual_sign">
								<div class="course-data">
									<span data-v-6af11ff6="" class="dot"></span>
									<span class="member-num">{{info.virtual_sign}}</span>
									<!-- <span data-v-6af11ff6="" class="dot"></span>
									<span data-v-6af11ff6="">6章</span>
									<span data-v-6af11ff6="">36节</span>
									<span data-v-6af11ff6="" class="dot"></span>
									<span data-v-6af11ff6="">来自设计学院</span> -->
								</div>
							</div>
							<div class="course-desc">
								{{info.summary}}
							</div>
						</div>
						<div class="wrap__bottom">
							<div class="detail-price"  v-if="info.price > 0">
								<span class="price-tag">￥</span>
								<span>{{info.price}}</span>
							</div>
							<!-- <div class="course-handle">
								<span> 立即报名 </span>
							</div> -->
						</div>
					</div>
				</div>
			</div>
			
			<div class="course-details-wrap_width" v-if="info.content">
				<div class="course-left_area">
					<div class="the-tabs">
						<ul class="heads">
							<li class="active">课程详情 </li>
						</ul>
					</div>
					<div class="description" >
						<div class="course_content_box" v-html="info.content">
						</div>
					</div>
				</div>
				<div class="course-right_area">
					<div class="label"></div>
					<div class="course-recommendation_wrap"></div>
				</div>
			</div>
			
		</div>
	</div>
	
</template>

<script>
import {courseDetail} from '@/api/public';	
export default {
	components: {
	},
	data() {
		return {
			info:{}
		};
	},
	created(){
		this.getCourseDetail()
	},
	methods:{
		viewDetail(e)
		{
			this.$router.push({path:'/course/detail?id='+e.id})
		},
		getCourseDetail()
		{
			courseDetail(this.$route.query.id).then(async (res) => {
				
				this.info = res.data;
			})
			.catch((res) => {
				//this.$Message.error(res.msg);
			});
		}
	}
};
</script>

<style scoped lang="scss">

.content_box{
	background-color: #F7F7F7;
}
.container{
	width:1200px;
	margin:0 auto;
	
}	


.breadcrumbs{
	width: 100%;
	display: flex;
	align-items: center;
	height: 52px;
	color: #a3a8ab;
	
	.sep{
		margin: 0 8px 0 18px;
	}
}

.course-body{
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 20px;
	
	.pic{
		width: 530px;
		height: 353px;
		margin-right: 20px;
		img{
			width: 100%;
			height: 100%;
			border-radius: 8px;
			-o-object-fit: cover;
			object-fit: cover;
		}
	}
	
	.course-info{
		flex: 1;
		box-sizing: border-box;
		position: relative;
		
		.course-info_wrap{
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			
			.wrap__top{
				h3{
					height: 45px;
					font-size: 32px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #18252c;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					max-width: 600px;
					margin: 0;
				}
				.course-data{
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #747c80;
					line-height: 6px;
					padding: 14px 0 0 0;
					.dot{
						display: inline-block;
						width: 6px;
						height: 6px;
						border-radius: 50%;
						background-color: #d1d3d5;
						vertical-align: top;
						margin: 0 8px;
					}
				}
				.course-desc{
					margin-top:24px;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #747c80;
					line-height: 20px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 6;
				}
			}
			
			.wrap__bottom{
				display: flex;
				justify-content: space-between;
				font-size: 40px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #ff2b26;
				.detail-price{
					.price-tag{
						font-size: 18px;
					}
				}
				.course-handle{
					width: 295px;
					height: 56px;
					font-size: 19px;
					line-height: 56px;
					text-align: center;
					background: linear-gradient(270deg, #ff4d40, #ff7e75);
					border-radius: 8px;
					cursor: pointer;
					span{
						font-size: 19px;
						font-family: PingFangSC-Semibold, PingFang SC;
						font-weight: 600;
						color: #fff;
						line-height: 26px;
					}
				}
			}
		}
	}
}

.course-details-wrap_width{
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 0 0 30px 0;
	
	.course-left_area{
		width: 850px;
		box-sizing: border-box;
		background-color: #fff;
		border-radius: 8px;
		padding: 20px 54px 18px 32px;
		.the-tabs{
			.heads{
				display: flex;
				li{
					min-width: 72px;
					height: 25px;
					font-size: 18px;
					font-family: PingFangSC-Semibold, PingFang SC, Helvetica, STHeiti, Microsoft YaHei, "sans-serif";
					font-weight: 600;
					color: #747c80;
					line-height: 25px;
					cursor: pointer;
					z-index: 10;
					text-align: center;
					&.active{
						font-weight: 600;
						color: #18252c !important;
						border-bottom: 4px solid #00d753;
					}
				}
			}
		}
		
		.description{
			margin-top: 25px;
			.course_content_box{
				
			}
		}
	}
}

</style>