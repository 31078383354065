import request from '@/libs/request';

export function getAjcaptcha(data) {
	
	return request({
		url: '/ajcaptcha',
		method: 'get',
		params:data
	});
}

export function ajcaptchaCheck(data) {
	return request({
		url: '/ajcheck',
		method: 'post',
		params:data
	});
}

/**
 * 获取短信KEY
 * @param object phone
 */
export function verifyCode() {
	
	return request({
		url: '/verify_code',
		method: 'get',
	});
}

/**
 * 验证码发送
 * @param object phone
 */
export function registerVerify(phone, reset, key, captchaType, captchaVerification) {
	
	return request({
		url: '/v3/register/verify',
		method: 'post',
		params:{
			phone: phone,
			type: reset === undefined ? 'reset' : reset,
			key: key,
			captchaType: captchaType,
			captchaVerification: captchaVerification
		}
	});
}