<template>
		<div class="container">
			<div v-html="info.content"></div>
		</div>
</template>

<script>
import {jobDetail} from '@/api/public';	
export default {
	components: {
	},
	data() {
		return {
			id:'',
			info:{}
		};
	},
	created(){
		this.id = this.$route.query.id
		this.jobDetail()
	},
	methods:{
		
		
		jobDetail()
		{
			
			jobDetail(this.id).then(async (res) => {
				this.info = res.data;
			})
			.catch((res) => {
				//this.$Message.error(res.msg);
			});
		}
	}
};		
</script>

<style scoped lang="scss">


.container{
	max-width: 1200px;
	height: auto;
	background-color: #fff;
	margin: 0 auto;
}
/deep/h3{
	
	margin-top:20px;
	margin-bottom: 10px;
}
/deep/p{
	font-size: 16px;
	color: #333;
	line-height: 30px;
	text-indent: 32px;
	margin-bottom: 10px;
}
.title{
	margin-top:40px;
	margin-bottom: 10px;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
}
</style>