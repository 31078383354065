import VueRouter from "vue-router";
import Home from '../views/index/index'

import Course from '../views/course/index'
import CourseDetail from '../views/course/detail'
import News from '../views/news/index'
import NewsDetail from '../views/news/detail'
import Job from '../views/job/index'
import JobDetail from '../views/job/detail'
import Cert from '../views/cert/index'
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
} //以上三行是为了解决相同路由报错的问题，不用深究，照原样放在此就行了
 
export default new VueRouter({
    mode: 'history',
    routes: [
        {path: '/', component: Home},
		{path: '/course', component: Course},
		{path: '/course/detail', component: CourseDetail},
		{path: '/news', component: News},
		{path: '/news/detail', component: NewsDetail},
		{path: '/job', component: Job},
		{path: '/job/detail', component: JobDetail},
		{path: '/cert', component: Cert},
		
		{
			path: '/user',
			name: 'user',
			component: () => import('@/views/user/index'),   // 这里配置首页默认路由跳转的页面组件
			redirect:'/user/course',
			children: [   // 添加子路由
				{
					path: 'course',  // 注意点：路径前面不要带/，否则无法跳转，在这里吃过亏
					name: 'userCourse',
					components: {
						userContent:() =>import('@/views/user/course/index')
					}  // 这里的table跟首页的router-view标签的name一致，才会在首页的路由视图进行跳转，看3.2
				},
				{
					path: 'job',  // 注意点：路径前面不要带/，否则无法跳转，在这里吃过亏
					name: 'userJob',
					components: {
						userContent:() =>import('@/views/user/job/index')
					}  // 这里的table跟首页的router-view标签的name一致，才会在首页的路由视图进行跳转，看3.2
				},
				
			]
		},
		
		{
			path: '/personal',
			name: 'personal',
			component: () => import('@/views/personal/index'),   // 这里配置首页默认路由跳转的页面组件
			redirect:'/personal/userinfo',
			children: [   // 添加子路由
				{
					path: 'userinfo',  // 注意点：路径前面不要带/，否则无法跳转，在这里吃过亏
					name: 'userinfo',
					components: {
						userContent:() =>import('@/views/personal/userinfo')
					}  // 这里的table跟首页的router-view标签的name一致，才会在首页的路由视图进行跳转，看3.2
				},
				{
					path: 'bindphone',  // 注意点：路径前面不要带/，否则无法跳转，在这里吃过亏
					name: 'bindphone',
					components: {
						userContent:() =>import('@/views/personal/bindphone')
					}  // 这里的table跟首页的router-view标签的name一致，才会在首页的路由视图进行跳转，看3.2
				},
				{
					path: 'edit_password',  // 注意点：路径前面不要带/，否则无法跳转，在这里吃过亏
					name: 'editPassword',
					components: {
						userContent:() =>import('@/views/personal/edit_password')
					}  // 这里的table跟首页的router-view标签的name一致，才会在首页的路由视图进行跳转，看3.2
				},
				{
					path: 'logout',  // 注意点：路径前面不要带/，否则无法跳转，在这里吃过亏
					name: 'logout',
					components: {
						userContent:() =>import('@/views/personal/logout')
					}  // 这里的table跟首页的router-view标签的name一致，才会在首页的路由视图进行跳转，看3.2
				},
				
			]
		},
		
		{
			path: '/playback',
			name: 'playback',
			component: () => import('@/views/play/playback'),   // 这里配置首页默认路由跳转的页面组件
		},
		
		{
			path: '/video',
			name: 'video',
			component: () => import('@/views/play/video'),   // 这里配置首页默认路由跳转的页面组件
		},
		{
			path: '/privacy',
			name: 'privacy',
			component: () => import('@/views/privacy/index'), 
		},
		{
			path: '/online',
			name: 'online',
			component: () => import('@/views/play/online'), 
		},
		
		{
			path: '/jobinfo', 
			name: 'jobInfo',
			component:() =>import('@/views/user/job/info')
		}
    ],
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	}
}) 