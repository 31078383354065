<template>
	<div>
		
		<div class="">
			<!-- <v-carousel v-model="model" class="banner" height="548">
				<v-carousel-item
				v-for="(item, index) in banner"
				:key="index"
				:src="item.thumb"
				>
				</v-carousel-item>
			</v-carousel> -->
			<el-carousel trigger="click"  height="548px" v-if="cfg.banner_is_show == '1'">
			  <el-carousel-item v-for="(item, index) in banner" :key="index">
				<img :src="item.thumb" style="width:100%;height:100%;"/>
			  </el-carousel-item>
			</el-carousel>
			<div class="courseBox"  v-if="cfg.course_is_show == '1'">
				<div class="courseBoxTitle">
					<img src="../../assets/left.png"/>
					<div> {{cfg.course_title}} </div>
					<img src="../../assets/right.png"/>
				</div>
				<CourseList :list="course"></CourseList>
				<!-- <button class="moreCourse"></button> -->
				<el-button
				rounded
				color="primary"
				dark
				class="moreCourse"
				@click="$router.push({path:'/course'})">
				查看更多正式课
				</el-button>
			</div>
			
			
			<div class="aboutBox"  v-if="cfg.about_is_show == '1'">
				<div class="aboutBoxTitle">
					<img src="../../assets/left.png"/>
					<div> {{cfg.about_title}} </div>
					<img src="../../assets/right.png"/>
				</div>
				<div class="aboutDetail" v-html="about.content">
				</div>
			</div>
			
			
			<div class="teseBox"  v-if="cfg.feature_is_show == '1'">
				<div class="teseBoxTitle">
					<img src="../../assets/left.png"/>
					<div> {{cfg.feature_title}} </div>
					<img src="../../assets/right.png"/>
				</div>
				
				<div class="teseList">
					<div class="teseItem" v-for="(item,index) in feature" :key="index">
						<div class="teseImgBox">
							<div class="teseImgInfo">
								<img :src="item.thumb" class="youshi_in_img">
								<!-- <span class="youshi_title">丰富的多元化课程</span> -->
							</div>
						</div>
						<div class="teseSummary" >{{item.summary}}</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
import {homeData} from '@/api/public';
import CourseList from '@/components/courseList';

export default {
	components: {
		CourseList
	},

	data() {
		return {
			banner:[],
			course:[],
			feature:[],
			about:{},
			cfg:{},
			model: 0,
			colors: [
				'primary',
				'secondary',
				'yellow darken-2',
				'red',
				'orange',
			],
		};
	},
	created(){
		this.getHomeData()
	},
	methods:{
		getHomeData()
		{
			homeData().then(async (res) => {
				
				this.banner = res.data.banner;
				this.course = res.data.course;
				this.feature = res.data.feature;
				this.about = res.data.about;
				this.cfg = res.data.cfg
				//this.$Message.success(res.msg);
			})
			.catch((res) => {
				//this.$Message.error(res.msg);
			});
		}
	}
};
</script>

<style scoped lang="scss">

.teseBox{
	background-color: rgb(255, 255, 255);
	width: 100%;
	margin: 0px auto;
	height: 680px;
	.teseBoxTitle{
		display: flex;
		justify-content: center;
		padding-bottom: 80px;
		padding-top: 60px;
		width: 1200px;
		margin: auto;
		div{
			color: rgb(0, 0, 0);
			font-size: 46px;
			font-weight: bold;
			line-height: 27px;
			padding: 0px 10px;
			margin:0 10px;
		}
		img{
			width: 46px;
			height: 27px;
		}
	}
	
	.teseList{
		display: flex;
		justify-content: space-between;
		width: 1200px;
		margin: auto;
		.teseItem{
			width: 256px;
			height: 333px;
			border-radius: 20px;
			background: #fff;
			cursor: pointer;
			box-shadow: rgba(204, 204, 204, 0.35) 0px 0px 40px 2px;
			.teseImgBox{
				width: 256px;
				height: 171px;
				border-radius: 20px 20px 0px 0px;
				align-items: center;
				margin: 0 auto;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				
				.teseImgInfo{
					display: flex;
					flex-direction: column;
					align-items: center;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					img{
						width: 100%;
						height: 100%;
					}
					span{
						font-size: 20px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						margin-top: 20px;
					}
				}
			}
			
			.teseSummary{
				padding: 20px 15px 0px;
				color: rgb(102, 102, 102);
				font-size: 16px;
			}
		}
		
		/* .teseItem:nth-child(1n) .teseImgBox{
			background: linear-gradient(270deg, #6CD9FC, #5699FF);
		}
		.teseItem:nth-child(2n) .teseImgBox{
			background: linear-gradient(270deg, #BF9CFE, #7869F8);
		}
		.teseItem:nth-child(3n) .teseImgBox{
			background: linear-gradient(270deg, #F8C052, #F47987);
		}
		.teseItem:nth-child(4n) .teseImgBox{
			background: linear-gradient(270deg, #69ECB5, #54C69D);
		} */
	}
}

.aboutBox{
	background-color: rgb(245, 247, 249);
	margin: 0px auto;
	width: 100%;
	padding-bottom: 30px;
	.aboutBoxTitle{
		display: flex;
		justify-content: center;
		padding-bottom: 80px;
		padding-top: 60px;
		width: 1200px;
		margin: auto;
		div{
			color: rgb(0, 0, 0);
			font-size: 46px;
			font-weight: bold;
			line-height: 27px;
			padding: 0px 10px;
			margin:0 10px;
		}
		img{
			width: 46px;
			height: 27px;
		}
	}
	.aboutDetail{
		margin: 0px auto;
		width: 1200px;
		padding: 64px 146px;
		background-color: rgb(255, 255, 255);
		border-radius: 30px;
		box-shadow: rgba(204, 204, 204, 0.35) 0px 0px 40px;
		text-align: left;
	}
}

/deep/.aboutDetail p{
	color: rgb(102, 102, 102);
	font-size: 14px;
	line-height: 32px;
}

/deep/.aboutDetail h2{
	color: rgb(40, 35, 49);
	font-size: 24px;
	font-weight: bold;
	
}

.courseBox{
	background-color: rgb(255, 255, 255);
	width: 1200px;
	height: auto;
	min-height: 800px;
	margin: 10px auto 100px;
	.courseBoxTitle{
		display: flex;
		justify-content: center;
		padding-bottom: 80px;
		padding-top: 60px;
		width: 1200px;
		margin: auto;
		div{
			color: rgb(0, 0, 0);
			font-size: 46px;
			font-weight: bold;
			line-height: 27px;
			padding: 0px 10px;
			margin:0 10px;
		}
		img{
			width: 46px;
			height: 27px;
		}
	}
	
	.moreCourse{
		width: 355px;
		height: 74px;
		background: #3C71FF!important;
		box-shadow: 0px 3px 30px 0px rgba(60, 113, 255, 0.4);
		border-radius: 37px;
		font-size: 24px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #FFFFFF;
		margin: 54px auto 0;
		display: block;
	}
}
</style>