<template>
	
	<div class="course-box">
		<div class="course-list acea-row ">
			<div class="course-item" v-for="(item,index) in list" :key="index" @click="viewDetail(item)">
				<!-- <img :src="item.thumb" class="pic"/> -->
				<div class="course-info">
					<div class="course-name">{{item.name}}</div>
					<div class="summary">{{item.summary}}</div>
				</div>
				
				<div class="opt-box acea-row row-between-wrapper" >
					<div class="price">金额：<span>{{item.price}}</span></div>
					<button class="mjs-btn">报名任务</button>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {
	},
	props: {
		list:[]
	},
	data() {
		return {
		};
	},
	
	methods:{
		viewDetail(e)
		{
			if(e.link_ty == 2){
				window.open(e.link)
			}else{
				this.$router.push({path:'/job/detail?id='+e.id})
			}
			
		}
	}
};
</script>

<style scoped lang="scss">
	
	
.course-box{
	margin-bottom: 30px;
	width: 1200px;
	margin:0 auto;
	.course-list{
		.course-item{
			background-color: #fff;
			border-radius: 8px;
			box-sizing: border-box;
			font-size: 0;
			margin: 0 24px 24px 0;
			padding: 24px 32px;
			width: 588px;
			
			
			img{
				width: 100%;
				height: 214px;
				border-radius: 10px;
				-o-object-fit: cover;
				object-fit: cover;
				-o-object-position: 50% 17%;
				object-position: 50% 17%;
			}
			
			.course-info{
				border-bottom: 1px solid #f0f2f5;
				padding-bottom: 20px;
				.course-name{
					color: #333;
					font-size: 20px;
					font-weight: 700;
					margin-bottom: 12px;
				}
				.summary{
					color: #666;
					font-size: 16px;
					font-weight: 500;
					margin: 20px 0 16px;
				}
				
			}
			
			.opt-box{
				color: #999;
				display: flex;
				font-size: 20px;
				font-weight: 500;
				justify-content: space-between;
				padding-top: 20px;
				width: 100%;
				.price{
					span{
						color: #ed6f22;
					}
				}
				
				.mjs-btn{
					background: linear-gradient(90deg,#ed9c22,#ed6f22);
					border-radius: 40px;
					box-sizing: border-box;
					color: #fff;
					cursor: pointer;
					display: inline-block;
					font-size: 16px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					width: 112px;
					border:0;
				}
			}
		}
		
		.course-item:hover{
			//padding: 8px;
			box-shadow: 3px 3px 29px 0px rgba(0, 0, 0, 0.15);
		}
		.course-item:nth-child(2n){
			margin: 0 0 20px 0;
		}
	}
}	
</style>