

import {getCookies,removeCookies} from '@/libs/util';
class Cache {

	clear(key)
	{
		removeCookies(key)
	}
	
	getToken()
	{
		let token = getCookies('token');
		if(!token) return '';
		if(!this.checkExpireTime()) return '';
		return token;
	}
	
	
	getUserInfo()
	{
		let userInfo = getCookies('userInfo');
		if(!userInfo) return {};
		if(!this.checkExpireTime()) return '';
		return userInfo;
	}
	
	checkExpireTime()
	{
		let expires_time = getCookies('expires_time')
		if(!expires_time) return false;
		if(expires_time < this.time()) return false;	
		return true;
	}
	
	
	/**
	 * 获取当前时间戳
	 */
	time() {
		return Math.round(new Date() / 1000);
	}

	
}


export default new Cache;
