<template>
	<div class="header">
		<div class="headerBox">
			<div class="daohang acea-row row-between-wrapper">
				<div class="acea-row">
					<div class="logo">
						<img :src="cfg.logo"/>
					</div>
					<div class="menus acea-row row-middle">
						<div class="menu-item" :class="current == '/' ? 'active' : ''" @click="swithMenu({link:'/',is_blank:0})">首页</div>
						<div v-for="(item,index) in menus" :key="index" class="menu-item" :class="current == item.link ? 'active' : ''" @click="swithMenu(item)">{{item.name}}</div>
					</div>
				</div>
				<!-- <div v-if="userInfo.uid" class="login" @click="$router.push({path:'/user'})">
					<el-popover
					    placement="bottom"
					    width="260"
					    trigger="hover">
						
						<div class="userInfo">
							<div class="userInfo-box acea-row row-middle">
								<el-avatar :size="50" :src="userInfo.avatar" class="avatar"></el-avatar>
								<div class="userInfo-box-name">
									<div class="userInfo-box-nickname line1">{{userInfo.nickname}}{{userInfo.nickname}}{{userInfo.nickname}}</div>
									<div class="userInfo-box-account line1">{{userInfo.account}}</div>
								</div>
							</div>
							<el-divider></el-divider>
							<div class="user-menu">
								<div class="user-menu-item" @click="$router.push({path:'/personal/userinfo'})">
									<i class="el-icon-setting user-menu-item-icon"></i>
									<span>账户设置</span>
								</div>
								<div class="user-menu-item" @click="logout">
									<i class="el-icon-switch-button user-menu-item-icon"></i>
									<span>退出登录</span>
								</div>
							</div>
						</div>
						
					    <el-avatar slot="reference" :size="40" :src="userInfo.avatar" class="avatar"></el-avatar>
					  </el-popover>
				</div>
				<div class="login" @click="login" v-else>登录/注册</div> -->
			</div>
		</div>
		
		<loginForm ref="loginForm"></loginForm>
		
	</div>
</template>

<script>
import loginForm from '@/components/loginForm';
import {mapState} from "vuex"
export default {

	components: {
		loginForm
	},
	props: {
		cfg:{},
		menus:[]
	},
	data() {
		return {
			current:'/',
			// menus:[
			// 	{name:'首页',code:'home',url:'/'},
			// 	{name:'课程',code:'course',url:'/course'},
			// 	{name:'兼职',code:'job',url:'/job'},
			// 	{name:'证书',code:'cert',url:'/cert'},
			// ],
			
		};
	},
	computed: {
		...mapState([
			'userInfo'
		])
	},
	created()
	{
		if(this.$route.path){
			this.current = this.$route.path;
		}else{
			this.current = '/';
		}
	},
	methods:{
		swithMenu(e)
		{
			this.current = e.link
			
			if(e.link.indexOf("http") == -1){
				//
				if(e.is_blank == 1){
					window.open(e.link)
				}else{
					this.$router.push({path:e.link})
				}
			}else{
				if(e.is_blank == 1){
					window.open(e.link)
				}else{
					location.href=e.link
				}
			}
			
			
		},
		login()
		{
			this.$refs.loginForm.centerDialogVisible = true;
		},
		logout()
		{
			this.$store.commit("LOGOUT");
			this.$router.push({path:'/'});
		}
	}
};
</script>

<style scoped lang="scss">
	
	
.header{
	height:70px;
	
}
.headerBox{
	position: fixed;
	z-index: 101;
	width: 100%;
	left:0;
	top:0;
	height: 70px;
	background: #FFFFFF;
	display: flex;
	box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.08);
}

.daohang{
	margin:0 auto;
	height: 70px;
	width: 1200px;
	align-items: center;
	.logo{
		margin:0 50px 0 100px;
		img{
			height: 50px;
			display: block;
		}
	}
	
	.menus{
		
		.menu-item{
			text-align: center;
			margin:0 40px;
			padding:14px 0;
			cursor: pointer;
			transition: border-color .3s,background-color .3s,color .3s;
			box-sizing: border-box;
			&.active{
				border-bottom: 2px solid #409EFF;
				color: rgb(15, 111, 237);
				border-bottom-color: rgb(15, 111, 237);
			}
		}
		
	}
	
	.login{
		margin:0 130px 0 0;
		cursor:pointer;
		.avatar{
			display: block;
		}
	}
}
	
.userInfo{
	padding:10px;
	color:#333;
	.userInfo-box{
		
		.userInfo-box-name{
			margin-left:10px;
			width:140px;
			.userInfo-box-nickname{
				font-size:20px;
			}
			
			.userInfo-box-account{
				color:#999;
				font-size:15px;
			}
		}
	}
	
	.user-menu{
		.user-menu-item{
			font-size:16px;
			line-height: 40px;
			cursor:pointer;
			.user-menu-item-icon{
				margin-right:10px;
			}
		}
	}
}	
</style>