

import axios from 'axios';
//import { Message } from 'iview';
import { getCookies, removeCookies } from '@/libs/util';
import Setting from '@/setting';
import router from '@/router';
//console.log(Setting.apiBaseURL)
const service = axios.create({
  baseURL: Setting.apiBaseURL,
  timeout: 10000, // 请求超时时间
});

axios.defaults.withCredentials = true; // 携带cookie

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    config.baseURL = Setting.apiBaseURL;
    if (config.file) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }
	
    const token = getCookies('token');
    if (token) {
      config.headers['Authori-zation'] = 'Bearer ' + token;
    }
	config.headers['Form-type'] = 'pc';
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  },
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    let obj = {};
    if (response.data) {
      if (typeof response.data == 'string') {
        obj = JSON.parse(response.data);
      } else {
        obj = response.data;
      }
    }
    let status = response.data ? obj.status : 0;
    //let status = response.data ? response.data.status : 0;
    const code = status;
    switch (code) {
      case 200:
        return obj;
      case 110002:
      case 110003:
      case 110004:
		removeCookies('token');
		removeCookies('userInfo');
		removeCookies('expires_time');
        //localStorage.clear();
		// store.commit("LOGOUT");
        router.replace({ path: '/' });
        break;
      case 110005:
      case 110006:
      case 110007:
        // removeCookies('kefuInfo');
        // removeCookies('kefu_token');
        // removeCookies('kefu_expires_time');
        // removeCookies('kefu_uuid');
        // router.replace({ path: '/kefu' });
        break;
      case 110008:
        //router.replace({ name: 'system_opendir_login' });
        break;
      default:
		//return obj;
        return Promise.reject(obj || { msg: '未知错误' });
    }
  },
  (error) => {
    //Message.error(error.msg);
    return Promise.reject(error);
  },
);

export default service;
