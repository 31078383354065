<template>
	<div class="footer">
		<div class="foot">
			<div class="wrap">
				<div class="f_tel">
					<span class="span1">客服电话：{{cfg.phone}}</span>
					<span class="span2">邮箱： {{cfg.email}}</span>
				</div>
			</div>
		</div>
		<div class="foot2">
			<div>{{cfg.copyright}} 网站备案号：<a href="http://beian.miit.gov.cn/">{{cfg.beian}}</a></div>
		</div>
		
	</div>
	
</template>

<script>
export default {

	components: {
	},
	props: {
		cfg:{}
	},
	data() {
		return {
			
		};
	},
	created()
	{
		//this.cfg = this.$store.state.cfg;
		//console.log()
	},
	methods:{
		
	}
};
</script>

<style scoped lang="scss">
	.footer{
		// height:150px;
		width:100%;
		background-color: #2a2d32;
		position: relative;
		width:100%;
		bottom:0;
		padding: 40px 0 0;
		color: #97999e;
		text-align: center;
		font-size:14px;
		.foot{
			.wrap{
				width: 100%;
				margin: 0 auto;
				max-width: 1200px;
				position: relative;
				.f_tel {
					padding: 20px 0;
					line-height: 20px;
					span{
						display: inline-block;
						padding-left: 25px;
						margin: 0 15px;
						&.span1{
							background: url(../assets/icon9.png) no-repeat left center;
						}
						&.span2{
							background: url(../assets/icon10.png) no-repeat left center;
						}
					}
				}
			}
		}
		
		.foot2{
			border-top: 1px solid #33373e;
			padding: 6px 0;
			color: #53585f;
			line-height: 24px;
			a{
				color: #97999e;
			}
		}
	}
</style>