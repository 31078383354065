
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import {
	LOGIN_STATUS,
	UID,
	USER_INFO
} from '../config/cache';
import Cache from '../utils/cache';

export default new Vuex.Store({
	state: {
		cfg:{},
		token: Cache.getToken() || false,
		userInfo:Cache.getUserInfo() || {}
	},
	mutations: {
		setCfg(state, opt) {
			state.cfg = opt.cfg;
		},
		setUserInfo(state, opt) {
			state.userInfo = opt;
		},
		
		LOGOUT(state) {
			state.token = false;
			state.userInfo = {}
			Cache.clear('token');
			Cache.clear('userInfo');
			Cache.clear('expires_time');
		},
	}
});

