import Vue from 'vue'
import App from './App.vue'
import store from './store/index';
import VueRouter from 'vue-router'
Vue.use(VueRouter) //启用vue-router插件
import router from './router/index' //引入我们自己的路由定义表
Vue.config.productionTip = false
import {Empty,Image,Carousel, CarouselItem ,Button,Dialog,Form,FormItem,Input,Notification,Avatar,Select,Radio,Checkbox,Upload,Pagination,Popover,Divider,Breadcrumb,BreadcrumbItem,Loading} from 'element-ui';
Vue.use(Button)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input);
Vue.use(Avatar);
Vue.use(Select);
Vue.use(Radio);
Vue.use(Upload);
Vue.use(Pagination);
Vue.use(Checkbox);
Vue.use(Popover)
Vue.use(Divider)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Loading)
Vue.use(Image)
Vue.use(Empty)
 
Vue.prototype.$notify = Notification;
import './styles/style.scss';
new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
