<template>
	
	<div class="course-box">
		<div class="course-list acea-row ">
			<div class="course-item" v-for="(item,index) in list" :key="index" @click="viewDetail(item)">
				<img :src="item.thumb" class="pic"/>
				<div class="course-info acea-row row-between-wrapper">
					<div class="course-name">{{item.name}}</div>
					<!-- <div class="course-labal">{{item.name}}</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {
	},
	props: {
		list:[]
	},
	data() {
		return {
		};
	},
	
	methods:{
		viewDetail(e)
		{
			this.$router.push({path:'/course/detail?id='+e.id})
		}
	}
};
</script>

<style scoped lang="scss">
	
	
.course-box{
	margin-bottom: 30px;
	width: 1200px;
	margin:0 auto;
	.course-list{
		.course-item{
			border-radius: 10px;
			background-color: #ffffff;
			padding: 8px;
			box-sizing: border-box;
			margin: 0 10px 20px 0;
			width: 292px;
			cursor: pointer;
			
			
			img{
				width: 100%;
				height: 214px;
				border-radius: 10px;
				-o-object-fit: cover;
				object-fit: cover;
				-o-object-position: 50% 17%;
				object-position: 50% 17%;
			}
			
			.course-info{
				line-height: 40px;
				.course-name{
					color: rgb(51, 51, 51);
					font-size: 16px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				
				.course-labal{
					min-width: 60px;
					height: 26px;
					background: #fffbf7;
					border: 1px solid #ffd4a7;
					border-radius: 10px;
					font-size: 12px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ff7c06;
					line-height: 26px;
					text-align: center;
					padding: 0px 5px;
				}
			}
		}
		
		.course-item:hover{
			padding: 8px;
			box-shadow: 3px 3px 29px 0px rgba(0, 0, 0, 0.15);
		}
		.course-item:nth-child(4n){
			margin: 0 0 20px 0;
		}
	}
}	
</style>