<template>
	<div class="page">
		<el-image
			:src="item.thumb" 
			class="banner"
			lazy
			v-for="(item,index) in banner" :key="index">
		</el-image>
		<div class="container" >
			
			<div class="page-title">
				全部兼职
			</div>
			
			<div style="padding: 0px 0px 40px; width: 1200px; margin: 0px auto;" v-if="cates.length > 0">
				<div style="display: flex; background-color: rgb(245, 246, 250); border-radius: 20px; padding: 0px;">
					<div class="titleTab " :class="'' == artForm.cate_id ? 'activeTab' : ''" @click="selectCate('')"> 全部 </div>
					<div class="titleTab" v-for="(item,index) in cates" :key="index" @click="selectCate(item.id)" :class="item.id == artForm.cate_id ? 'activeTab' : ''"> {{item.name}} </div>
				</div>
			</div>
			
			<div v-if="list.length > 0">
				<jobList :list="list" ></jobList>
				
				
				<div class="pagination" v-if="total > artForm.limit">
					<el-pagination
					  background
					  layout="prev, pager, next"
					  :page-size="artForm.limit"
					  :total="total"
					  @current-change="pageChange">
					</el-pagination>
				</div>
				
			</div>
			
			<el-empty description="暂无兼职信息" v-else></el-empty>
		</div>
	</div>
	
</template>

<script>
import {jobPageList,jobCategoryList,jobData} from '@/api/public';	
import jobList from '@/components/jobList';
export default {
	components: {
		jobList
	},
	data() {
		return {
			artForm:{
				page:1,
				limit:6,
				cate_id:''
			},
			total:0,
			list:[],
			cates:[],
			banner:[],
			loading:''
			// attrs: {
			// 	class: 'mb-6',
			// 	boilerplate: true,
			// 	elevation: 2,
			// },
		};
	},
	created(){
		this.categoryList()
		this.getList()
		this.getConfigData();
	},
	methods:{
		categoryList()
		{
			
			jobCategoryList(this.artForm).then(async (res) => {
				
				this.cates = res.data;
			})
			.catch((res) => {
				//this.$Message.error(res.msg);
			});
		},
		getList()
		{
			this.loading = this.$loading({
			  lock: true,
			  text: 'Loading',
			  spinner: 'el-icon-loading',
			  background: 'rgba(0, 0, 0, 0.7)'
			});
			jobPageList(this.artForm).then(async (res) => {
				
				this.list = res.data.list;
				this.total = res.data.count
				this.loading.close();
			})
			.catch((res) => {
				//this.$Message.error(res.msg);
			});
		},
		getConfigData()
		{
			jobData().then(async (res) => {
				
				this.banner = res.data.banner;
				
			})
			.catch((res) => {
				//this.$Message.error(res.msg);
			});
		},
		selectCate(id)
		{
			this.artForm.cate_id = id
			this.getList()
		},
		pageChange(e)
		{
			this.artForm.page = e;
			this.getList()
		}
	}
};
</script>

<style scoped lang="scss">
.banner{
	width:100%;
}	
.page{
	background-color: #f5f7f9;;
}
.container{
	width:1200px;
	margin:0 auto;
	padding-bottom: 100px;
	
}	


.page-title{
	padding: 69px 0px;
	display: flex;
	justify-content: space-between;
	width: 1200px;
	margin: 0px auto;
	align-items: center;
	color: rgb(51, 51, 51);
	font-size: 32px;
	padding-left: 18px;
}


.pagination{
	text-align: center;
	margin-top:60px;
}


.titleTab{
	color: #999999;
	font-size: 14px;
	padding: 12px 34px;
	cursor: pointer;
}

.activeTab{
    background: #ffffff;
    box-shadow: 0px 3px 18px 0px rgba(47, 130, 255, 0.08);
    border-radius: 21px;
    color: #0f6fed;
}
</style>