<template>
	
	<div class="course-box">
		<div class="course-list acea-row ">
			<div class="course-item acea-row" v-for="(item,index) in list" :key="index" @click="viewDetail(item)">
				<img :src="item.image_input[0]" class="pic"/>
				<div class="course-info">
					<div class="course-name">{{item.title}}</div>
					<div class="summary">{{item.summary}}</div>
					<div class="desc">{{item.add_time}}</div>
				</div>
				
				
				
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {
	},
	props: {
		list:[]
	},
	data() {
		return {
		};
	},
	
	methods:{
		viewDetail(e)
		{
			if(e.link_ty == 2){
				window.open(e.link)
			}else{
				this.$router.push({path:'/job/detail?id='+e.id})
			}
			
		}
	}
};
</script>

<style scoped lang="scss">
	
	
.course-box{
	margin-bottom: 30px;
	width: 1200px;
	margin:0 auto;
	.course-list{
		.course-item{
			margin-bottom: 16px;
			width: 925px;
			
			
			img{
				height: 170px;
				overflow: hidden;
				width: 300px;
				transition: all 1s;
			}
			img:hover {
				transform: scale(1.1);
			}
			.course-info{
				flex: 1;
				min-width: 0;
				padding-left: 24px;
				position: relative;
				.course-name{
					font-size: 16px;
					font-weight: 700;
					margin-bottom: 16px
				}
				.summary{
					color: #999;
					font-size: 14px;
					line-height: 26px;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					display: -webkit-box;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				
				.desc{
					bottom: 26px;
					color: #999;
					font-size: 12px;
					left: 24px;
					position: absolute;
				}
				
			}
			
		}
		
		// .course-item:hover{
		// 	//padding: 8px;
		// 	box-shadow: 3px 3px 29px 0px rgba(0, 0, 0, 0.15);
		// }
		// .course-item:nth-child(2n){
		// 	margin: 0 0 20px 0;
		// }
	}
}	
</style>