

import Cookies from 'js-cookie';

// 设置setCookies；
// setToken
export const setCookies = (key, val, cookieExpires) => {
  Cookies.set(key, val, { expires: cookieExpires || 1 });
};
// 获取getCookies；
// getToken
export const getCookies = (key) => {
  return Cookies.get(key);
};

export const removeCookies = (key) => {
  return Cookies.remove(key);
};

