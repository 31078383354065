<template>
  <v-app class="main">
	<Header :cfg="cfg" :menus="menus" class="main_header"/>
    <router-view class="main_content"/>
	<Footer :cfg="cfg"/>
  </v-app>
</template>

<script>
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import {getUserInfo} from '@/api/user';
import {getSystemData} from '@/api/public';
import {setCookies,getCookies} from '@/libs/util';
export default {
	name: 'xxApp',

	components: {
		Header,
		Footer
	},
	data() {
		return {
			cfg:{},
			menus:[]
		};
	},
	created(){
		this.getSystemData();
		//this.initUserInfo();
	},
	methods:{
		initUserInfo()
		{
			if(this.$store.state.token){
				getUserInfo().then(async (r) => {
					if(r){
						this.$store.commit('setUserInfo', r.data);
					}
				});
			}
		},
		getSystemData()
		{
			
			getSystemData().then(async (res) => {
				this.cfg = res.data.cfg;
				this.$store.commit('setCfg', res.data.cfg);
				this.menus = res.data.navigation
				document.title = res.data.cfg.name
				
				// if(this.cfg.wap_domain && this.isMobile()){
				// 	window.location.replace(this.cfg.wap_domain)
				// }
				
				// this.banner = res.data.banner;
				// this.course = res.data.course;
				// this.feature = res.data.feature;
				// this.about = res.data.about;
				//this.$Message.success(res.msg);
			})
			.catch((res) => {
				//this.$Message.error(res.msg);
			});
		},
		isMobile() {
		    return /(iPhone|iPad|iPod|iOS|Android|Linux armv8l|Linux armv7l|Linux aarch64)/i.test(navigator.platform);
		}
	}

};
</script>
